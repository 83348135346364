import React, { useState, useEffect } from 'react';
import { Radio } from "antd";
import DashboardGraphCard from '../DashboardGraphCard/DashboardGraphCard';
import { getGraphData } from '../../../utilities/apiHandlers';
import Trends from '../Trends';
import { Row, Col, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import './GraphLayout.css';
import BackdropWithModal from '../BackDropModal/Backdrop';

function GraphLayout() {

  let [allGraphData, setAllGraphData] = useState([]);
  let [graphData, setGraphData] = useState([]);
  let [reqRegion, setReqRegion] = useState('all');
  let [lastUpdatedAt, setLastUpdatedAt] = useState();

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const [isHovered, setIsHovered] = useState(false);

  const getUnitCount = (countData) => {
    let output = countData.reduce((total, item) => total + (item.count || 0), 0);
    return output ? output.toLocaleString('en-US') : null;
  }

  function processCountsByKey(dataArray, keyName) {
    // Create an object to hold the aggregated data
    const countsByKey = {};

    // Label NA or EU for default device
    if (keyName === 'device') {
      dataArray?.forEach(row => {
        if (row['device'] === 'default') {
          if (row['region'] === "NA") row['device'] = 'default_na';
          else if (row['region'] === "EU") row['device'] = 'default_eu';
        }
      })
    }

    // Iterate through each object in the array
    dataArray?.forEach(item => {
      const key = item[keyName];
      const count = item.count;
      if (countsByKey[key]) {
        countsByKey[key] += count;
      } else {
        countsByKey[key] = count;
      }
    });

    // Convert the aggregated data back into an array of objects
    const resultArray = Object.keys(countsByKey).map(key => {
      return { [keyName]: key, count: countsByKey[key] };
    });

    return resultArray;
  }
  const [totalCount, setTotalCount] = useState({})

  useEffect(() => {
    async function getGraphDataFunction() {
      let data = await getGraphData({ region: '' });
      setLastUpdatedAt(moment(data[0].value[0].created_at).format('hh:mm a, ddd, MMM Do YYYY Z'));
      let totalCount = {};
      data.forEach(entry => {
        entry.value = entry.value.filter(item => {
          if (item.device === 'total') {
            totalCount[item.region] = item.count;
            return false;
          }
          return true;
        });
      });
      setTotalCount(totalCount);
      await setAllGraphData([...data]);
    }
    getGraphDataFunction();
  }, []);

  useEffect(() => {
    if (reqRegion === 'all') {
      setGraphData([...allGraphData]);
    }
    else {
      // Filter the data based on the selected region
      let graphData = allGraphData.map(entry => ({
        ...entry,
        value: entry.value.filter(item => item.region === reqRegion)
      }));
      setGraphData([...graphData]);
    }
  }, [allGraphData, reqRegion]);

  const handleRegionChange = (e) => {
    setReqRegion(e.target.value);
  };

  return (
    <div>
      <div className="title-strip" style={{ position: 'fixed', background: '#fffd', zIndex: '10', width: 'calc(100% - 11rem)', marginTop: '-1.75rem', padding: '1rem', borderBottom: '1px solid #0003' }}>
        <div>
          <Radio.Group value={reqRegion} onChange={handleRegionChange}>
            <Radio.Button value="all">GLOBAL</Radio.Button>
            <Radio.Button value="NA">NA</Radio.Button>
            <Radio.Button value="EU">EU</Radio.Button>
          </Radio.Group>
        </div>
        <div style={{ margin: '-0.5rem 0' }}>
          <i>Data shown is from Jan 1st, 2023 till present</i><br />
          {lastUpdatedAt && <>
            Last Updated at <b>{lastUpdatedAt}</b>
          </>}
        </div>
      </div>
      <BackdropWithModal open={modalOpen} onClose={handleClose} Modal_Title={"Features"} Content={graphData[1]?.value} />
      <Row style={{ paddingTop: '2.5rem' }}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <Card style={{ margin: '8px 8px' }}>
            <b>Total VINs</b>
            <p style={{ fontSize: '40px' }}>{(reqRegion === 'all' ? (totalCount['NA'] + totalCount['EU']).toLocaleString('en-US') : totalCount[reqRegion].toLocaleString('en-US'))
              || <LoadingOutlined />}</p>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
          <Card style={{ margin: '8px 8px' }}>
            <b>Total Features</b>
            <p
              onClick={handleOpen}
              className='hover-effect'
            >
              {graphData[1]?.value?.length || <LoadingOutlined />}
            </p>
          </Card>
        </Col>
        {graphData.length === 0 &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <hr style={{ backgroundColor: '#ccc', height: '1px', border: '0' }} /><br />
            <center><LoadingOutlined style={{ fontSize: '10rem', color: '#143A84' }} /></center><br /><br />
          </Col>
        }
        {graphData[11]?.value?.length > 0 &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <hr style={{ backgroundColor: '#ccc', height: '1px', border: '0' }} /><br />
            <Trends scope="daily_unique" historicData={graphData[11]?.value || []} /><br />
            <hr style={{ backgroundColor: '#ccc', height: '1px', border: '0' }} /><br />
          </Col>
        }
        {graphData[10]?.value?.length > 0 &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Trends scope="total_unique" historicData={graphData[10]?.value || []} /><br />
            <hr style={{ backgroundColor: '#aaa', height: '1px', border: '0' }} /><br />
          </Col>
        }
        {graphData[2]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="energy" graphName="Vehicle Type Count" graphData={processCountsByKey(graphData[2]?.value, 'energy')} />
          </Col>
        }
        {graphData[3]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="brandname" graphName="Top Brands using ADA" graphData={processCountsByKey(graphData[3]?.value, 'brandname')} />
          </Col>
        }
        {graphData[4]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="device" graphName="Device Vehicle Count" graphData={processCountsByKey(graphData[4]?.value, 'device')} />
          </Col>
        }
        {graphData[6]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="countryname" graphName="Vehicle Count by Countries" graphData={processCountsByKey(graphData[6]?.value, 'countryname')} />
          </Col>
        }
        {graphData[5]?.value?.length > 0 &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <DashboardGraphCard labelTag="modelname" graphName="Top Models using ADA" graphData={processCountsByKey(graphData[5]?.value, 'modelname')} />
          </Col>
        }
        {graphData[7]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="feature" graphName="Top 10 feature on VP4R" graphData={processCountsByKey(graphData[7]?.value, 'feature')} />
          </Col>
        }
        {graphData[8]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="feature" graphName="Top 10 feature on R1" graphData={processCountsByKey(graphData[8]?.value, 'feature')} />
          </Col>
        }
        {graphData[9]?.value?.length > 0 &&
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DashboardGraphCard labelTag="feature" graphName="Top 10 feature on TBM" graphData={processCountsByKey(graphData[9]?.value, 'feature')} />
          </Col>
        }
      </Row>
    </div>
  );
}

export default GraphLayout;
