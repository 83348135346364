import React, { useState } from 'react';
import { Upload, Button, Radio, Divider, App, Progress, Alert, Collapse, Popconfirm, Tabs, Checkbox, Tag, Tree, Input, Switch, Tooltip } from 'antd'
import { CheckCircleFilled, FileExcelOutlined, LoadingOutlined, WarningFilled, DownOutlined, FileDoneOutlined, UploadOutlined, PlusOutlined, MinusOutlined, ExportOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import './BatchAnalysis.css';
import { getVINProfile, getVINProfileByDevice } from '../../utilities/apiHandlers';
import { saveAs } from 'file-saver';
import { adaWebEndpoints, adaWebURL } from '../../config';

const { Dragger } = Upload;
dayjs.extend(relativeTime);

export default function BatchAnalysis() {

  const VinProfileFields = {
    'Check-in': ['last_check_in_env', 'last_check_in_region', 'last_check_in_source', 'appVersion', 'fwVersion', 'vendor'],
    'Timeline': ['last_check_in', 'estimatedNextCheckIn', 'exp_date', 'debugExpiry', 'lastKnownExpiry'],
    'Flags': ['scheduling', 'notifications', 'lastKnown', 'debug']
  };
  const [VINProfileFieldsMap, setVINProfileFieldsMap] = useState({
    'id': 'Policy ID',
    'last_check_in': 'Last Check In',
    'last_check_in_source': 'Last Check In Source',
    'last_check_in_env': 'Last Check In Env',
    'last_check_in_region': 'Last Check In Region',
    'exp_date_extend': 'Expiry Date Extend',
    'scheduling': 'Scheduling',
    'notifications': 'Notifications',
    'lastKnown': 'Last Known',
    'debug': 'Debug',
    'exp_date': 'Policy Expiry',
    'debugExpiry': 'Debug Expiry',
    'lastKnownExpiry': 'Last Known Expiry',
    'estimatedNextCheckIn': 'Estimated Next Check In',
    'renew': 'Renew',
    'appVersion': 'App Version',
    'fwVersion': 'Firmware Version',
    'vendor': 'Vendor'
  });
  const VINProfileFieldsCheckboxOptions = [
    { label: 'VIN', value: 'vin', disabled: true },
    { label: 'Device', value: 'device', disabled: true },
    ...(Object.keys(VINProfileFieldsMap).map(key => ({
      label: VINProfileFieldsMap[key],
      value: key
    })))
  ];




  const [region, setRegion] = useState('na');
  const [mode, setMode] = useState('text');
  const [vins, setVins] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [textInput, setTextInput] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [vinProfiles, setVinProfiles] = useState({});
  const [expandedVins, setExpandedVins] = useState([]);
  const [processedVinsCount, setProcessedVinsCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [syncTabs, setSyncTabs] = useState(true);
  const [currentTab, setCurrentTab] = useState('info');
  const [expandedSource, setExpandedSource] = useState([]);
  const [exportExcelFields, setExportExcelFields] = useState(['vin', 'device']);
  const { message } = App.useApp();

  const handleFileChange = async (info) => {
    if (mode === 'excel') {  
      setVins([]); 
      setFileList([]); 
      setVinProfiles({}); 
      setProcessedVinsCount(0); 
      setProgress(0);
      setLoaded(false);
      if (!info.fileList.length) {
        message.info("Input file cleared.");
      }
      else {      
        const data = await info.file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const vins = Array.from(new Set(XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: ""
        }).map(row => row[0])));
        if (vins.length && vins[0].length > 7 && vins.length <= 100) {
          message.loading('Validating VIN List...',2);
          getVINProfile(vins[0],region).then(data => {
            if (data?.vin === vins[0]){
              setFileList([...info.fileList]);
              setVins([...vins]);
            } 
            else {
              setFileList([]);
              setVins([]);
              message.error(
                <div>
                  Your list of VINs seems to be invalid. <br />
                  <ul style={{textAlign:'left', marginRight:'2rem'}}>
                    <li>Check selected region.</li>
                    <li>Check if the first column contains VINs.</li>
                    <li>Ensure the VINs are in the first sheet.</li>
                    <li><b>No headers</b></li>
                  </ul>
                </div>,
                10
              );
            }
          }).catch(err => {
            console.log(err);
            setFileList([]);
            setVins([]);
            message.error("VIN list could not be validated.", 5);
          })
        }
        else if (vins.length > 100) {
          setFileList([]);
          setVins([]);
          message.error(
            <div>
              Your file contains more than 100 VINs. <br />
              Please post larger requests in <b>#data-campaigns</b> channel on Discord.
            </div>,
            10
          );
        }
        else {
          message.error('File seems to be invalid. Please check your file.')
        }
      }
    }
    else if (mode === 'json') {
      const fileReader = new FileReader();
      fileReader.readAsText(info.file, "UTF-8");
      fileReader.onload = e => {
        const content = e.target.result;
        setVinProfiles(JSON.parse(content));
        setLoaded(true);
        setFileList(info.fileList);
        setVins(Object.keys(JSON.parse(content)));
      };
    }
  }

  const handleTextAreaInput = (e) => {
    let text = e.target.value;
    text = text.trim();
    if(text.length > 0) {
      let vinsFromText = [...new Set(text.split(/[\s,]+/))];
      setTextInput(vinsFromText.join('\n'));
      setVins(vinsFromText);
    }
  }

  const handleGetVinProfiles = async () => {
    setSubmitting(true);
    let vinProfilesData = {};
    let doneCount = 0;
    let errorCount = 0;
    await Promise.all(vins.map(async vin => {
      await getVINProfileByDevice(vin,region).then(data => {
        if (data?.data?.[0]?.vin){
          doneCount += 1;
          vinProfilesData[vin] = data['data'];
        }
        else {
          errorCount+=1;
          message.error(`VIN does not exist: ${vin}.`);
        }
        setProcessedVinsCount(doneCount+errorCount);
        setProgress(((doneCount+errorCount) / vins.length) * 100);
        // console.log(doneCount, errorCount, vins.length);
        if ((doneCount+errorCount) === vins.length) {
          // console.log(vinProfilesData);
          message.info(`Processed ${doneCount+errorCount} VINs. | Found: ${doneCount} | Invalid: ${errorCount}`);
          if (doneCount > 0) 
            {
              console.log(vinProfilesData)
            Object.keys(vinProfilesData).forEach(vin => {
              vinProfilesData[vin].forEach(profile => {
              Object.keys(profile).forEach(field => {
                const lowerCaseField = field.toLowerCase();
                if (!['vin', 'device'].includes(lowerCaseField) && !Object.keys(VINProfileFieldsMap).some(key => key.toLowerCase() === lowerCaseField)) {
                setVINProfileFieldsMap(prevState => ({
                  ...prevState,
                  [field]: field
                }));
                }
              });
              });
            });
            setVinProfiles({...vinProfilesData});
            setSubmitting(false);
            setLoaded(true);
          }
          else {
            setSubmitting(false);
            message.error('No valid VINs were found. Please check list of VINs.',10);
          }
        }
      }).catch(err => {
        console.log(err);
        errorCount+=1;
        message.error(`Error getting VIN profile for ${vin}`);
      });
    }));
  }

  const exportVinProfilesAsJSON = () => {
    const filename = `${Object.keys(vinProfiles).length} VIN Profiles ${dayjs().format('h.mm.ssa DD-MMM')}.json`;
    saveAs(
      new Blob([JSON.stringify(vinProfiles, null, 4)], { type: "text/plain;charset=utf-8" }),
      filename
    );
  }

  const exportDataToExcel = () => {
    const filename = `${Object.keys(vinProfiles).length} VINs ${dayjs().format('h.mm.ssa DD-MMM')}.xlsx`;
    const workbook = XLSX.utils.book_new();
    let exportData = [];
    Object.keys(vinProfiles).forEach(key => {
      vinProfiles[key].forEach(profile => {
        let row = {};
        exportExcelFields.forEach(field => {
          let value = profile[field];
          if (Array.isArray(value) || typeof value === 'object') {
            value = JSON.stringify(value);
          }
          row[field] = value || '-';
        });
        exportData.push(row);
      });
    });
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "VIN Profiles");
    XLSX.writeFile(workbook, filename);
  }

  const getInfoTabItems = (vin) => {
    let data = vinProfiles[vin];
    let items = [
      {
        key: 'visual', 
        label: 'Visualize', 
        children: visualizeVinData(data)
      },
      {
        key: 'raw', 
        label: 'Raw JSON', 
        children:(<div className='json'>{JSON.stringify(data,null,2)}</div>)
      }
    ];
    return items;
  }

  const visualizeVinData = (data) => {
    let deviceProfiles = data.map(profile => ({
      key: profile.device,
      label: profile.device.toUpperCase(),
      children: renderDeviceProfile(profile)
    }))
    return(
      <Tabs
        style={{marginLeft:'1rem'}}
        size='small'
        items={deviceProfiles}
      />
    )
  }

  const renderDeviceProfile = (profile) => {
    let featureRequests = {}
    if (profile.featureRequests?.length) {
      profile.featureRequests.forEach(sourceReq => {
        featureRequests[sourceReq.source] = {};
        sourceReq.features.forEach(featureReq => {
          featureRequests[sourceReq.source][featureReq.name] = featureReq.expiryDate;
        });
      });
    }
    let basicInfoTab = {
      key: 'info',
      label: 'Info',
      children: (
        <table style={{borderCollapse:'collapse'}}><tbody>
          <tr>
            <td>Policy ID</td><td>:</td>
            <td>
              <Tooltip title='Show in ADA Web' placement='right' overlayInnerStyle={{fontSize:'0.7em',minHeight:'1em', padding:'4px 8px'}}>
                <span 
                  className='ada-link' style={{fontSize:'1.1em', fontWeight:'500'}} 
                  onClick={() => {if (profile.id) window.open(new URL(`${adaWebEndpoints.showPolicyEndpoint}/${profile.id}`, adaWebURL), '_blank');}} 
                >
                  {profile.id || '-'}
                </span>
              </Tooltip>
            </td>
          </tr>
          <tr>
            <td>Active Features</td><td>:</td>
            <td>
              {profile.activeFeatures ? 
                profile.activeFeatures.split(',').map(fname => 
                  <Tooltip key={fname} title='Show in ADA Web' placement='top' overlayInnerStyle={{fontSize:'0.7em',minHeight:'1em', padding:'4px 8px'}}>
                    <Tag 
                      key={fname} 
                      onClick={() => {window.open(new URL(`${adaWebEndpoints.showPolicyEndpoint}/${fname}`, adaWebURL), '_blank');}} 
                      className='fname' 
                      color='blue'
                    >
                      {fname}
                    </Tag>
                  </Tooltip>
                ) 
                : 'None'
              }
            </td>
          </tr>
          <tr style={{height:'1em'}}></tr>
          <tr style={{borderTop:'solid thin #ddd'}}>
            <td>Feature Requests</td><td>:</td>
            <td>
              {Object.keys(featureRequests).length ? 
                <Tree 
                  defaultExpandParent={false} 
                  selectable={true} 
                  showLine 
                  switcherIcon={<DownOutlined />} 
                  expandedKeys={expandedSource}
                  selectedKeys={expandedSource}
                  onSelect={setExpandedSource} 
                  onExpand={setExpandedSource}
                  treeData={
                    Object.keys(featureRequests).map(source => ({
                      title: (
                        <span style={{fontWeight:'400'}}>
                          Source : <span style={{fontWeight:'500'}}>{source}&emsp;</span> 
                          [{Object.keys(featureRequests[source]).length || 'no'} feature{Object.keys(featureRequests[source]).length === 1 ? '' : 's'}]
                        </span>
                      ),
                      key: `${profile.vin}.${profile.device}.${source}`,
                      children: Object.keys(featureRequests[source]).map(feature => ({
                        title: (
                          <span>
                            <Tooltip title='Open in ADA Web' placement='top' overlayInnerStyle={{fontSize:'0.7em',minHeight:'1em', padding:'4px 8px'}}>
                              <span 
                                className='ada-link' 
                                style={{paddingTop:'4px'}} 
                                onClick={() => {window.open(new URL(`${adaWebEndpoints.showPolicyEndpoint}/${feature}`, adaWebURL), '_blank');}} 
                              >
                                {feature}
                              </span>
                            </Tooltip>:&emsp;
                            <span title={dayjs(featureRequests[source][feature]).format('DD MMM YYYY | hh:mm:ssa | UTCZ')}>Expire{(dayjs() < dayjs(featureRequests[source][feature])) ? 's': 'd'} {dayjs(featureRequests[source][feature]).fromNow()}</span>
                          </span>
                        ),
                        key: `${profile.vin}.${profile.device}.${source}.${feature}`,
                        children: []
                      }))
                    }))
                  } 
                /> 
                : 'None'
              }
            </td>
          </tr>
        </tbody></table>
      )
    }
    let fieldTypes = Object.keys(VinProfileFields).map(fieldType => {
      let showGroup = false;
      let availableFields = Object.keys(profile);
      for (let field of VinProfileFields[fieldType]) {
        if (availableFields.includes(field)){
          showGroup = true;
          break;
        }
      }
      return ({
        key: fieldType,
        label: fieldType,
        children: (
            <table><tbody>
              {VinProfileFields[fieldType].map(field => Object.keys(profile).includes(field) && (
                <tr key={field}>
                  <td>{VINProfileFieldsMap[field]}</td>
                  <td>:</td>
                  <td>
                    {fieldType === 'Timeline'? 
                      <><b>{dayjs(profile[field]).fromNow()}</b>&emsp;({dayjs(profile[field]).format('DD MMM YYYY | hh:mm:ssa | UTCZ')})</> : 
                      <b>{String(profile[field])}</b>
                    }
                  </td>
                </tr>
              ))}
              {!showGroup && <tr><td ><center>No fields available</center></td></tr>}
            </tbody></table>
        )
      });
    });
    return(
      <Tabs
        size='small'
        type='card'
        items={[basicInfoTab, ...fieldTypes]}
        {...(syncTabs && {activeKey: currentTab, onChange: setCurrentTab})}
      />
    )
  }

  return (
    <div id='form-container'>
      {!loaded && 
        <>
          <div className="input-label">Region</div>
          <Radio.Group
            options={[
              { label: <div className='radio-options'>NA</div>, value: 'na' },
              { label: <div className='radio-options'>EU</div>, value: 'eu' },
            ]}
            disabled={submitting}
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            optionType='button'
            buttonStyle='solid'
          />
          <Divider style={DividerCSS} dashed />
          <Radio.Group 
            options={[
              {label: <div className='radio-options'>Paste VINs</div>, value: 'text'},
              {label: <div className='radio-options'>Upload Excel</div>, value: 'excel'},
              {label: <div className='radio-options'>Load JSON</div>, value: 'json'}
            ]}
            disabled={submitting}
            value={mode}
            onChange={(e) => {setMode(e.target.value); setVins([]); setFileList([]); setVinProfiles({}); setProcessedVinsCount(0); setProgress(0);}}
          />
          <Divider style={DividerCSS} dashed />
          {mode === 'text' ?
            <>
              <Input.TextArea 
                rows={4} 
                placeholder='Enter VINs separated by spaces, commas or lines.' 
                onChange={e => setTextInput(e.target.value)}
                onBlurCapture={handleTextAreaInput} 
                value={textInput}
                disabled={submitting}
                style={{marginBottom:'1em'}}
              />
              {vins.length > 0 && <Tag color='green'>{vins.length} VINs</Tag>}
            </> :
            <Dragger
              type='file'
              multiple={false}
              maxCount={1}
              disabled={submitting}
              customRequest={({ onSuccess }) => { onSuccess("ok"); }}
              onChange={handleFileChange}
              beforeUpload={() => false}
              onRemove={(file) => { setVins([]); setFileList([]); setVinProfiles({}); setProcessedVinsCount(0); setProgress(0); }}
              fileList={fileList}
              accept={mode==='excel' ? ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" : ".json"}
              style={{ backgroundColor: '#efefef', borderWidth: '3px' }}
            >
              {(vins.length && fileList.length) ?
                <>
                  <p className="ant-upload-drag-icon">
                    <CheckCircleFilled style={{ fontSize: '2.5em', color: 'limegreen' }} />
                  </p>
                  <p className="ant-upload-text">Unique VINs: <b>{vins.length}</b></p>
                  <p className="ant-upload-hint">
                    Click here to replace current file.
                  </p>
                </>
                :
                <>
                  <p className="ant-upload-drag-icon">
                    {mode === 'excel' ?
                      <FileExcelOutlined style={{ fontSize: '2.5em' }} />:
                      <FileDoneOutlined style={{ fontSize: '2.5em' }}/>
                    }
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    {mode === 'excel'  && <b>Max. 100 VINs<br/></b>}
                    {mode === 'excel' ? 
                      <>Upload excel file containing only list of VINs with no headers.</> :
                      <>Select JSON file exported from previous batch analysis.</>
                    }
                  </p>
                </>
              }
            </Dragger>
          }
          <Divider style={DividerCSS} dashed />
        </>
      }
      {vins.length > 0 && (fileList.length > 0 || mode === 'text') &&
        <>
          {!submitting  && !loaded &&
            <Button type='primary' size='large' onClick={handleGetVinProfiles} style={{width:'max-content', padding:'auto 1rem'}}>
              Get Info
            </Button>
          }
          {submitting &&
            <div>
              <Alert 
                message={submitting ? "Getting VIN profiles, this may take a while..." : "VIN profiles retrieved."}
                type='info'
                icon={submitting ? <LoadingOutlined /> : <CheckCircleFilled style={{ color: 'limegreen' }} />}
                showIcon
                style={{marginBottom:'1rem'}}
              />
              <Progress 
                percent={progress} 
                status={submitting? 'active' : 'success'} 
                showInfo={true} 
                size={['default',10]} 
                format={(p) => processedVinsCount + '/' + vins.length}
              />
              <Divider style={DividerCSS} dashed />
            </div>
          }
          {!submitting  && loaded &&
            <>
              <Popconfirm 
                okText={<><WarningFilled />&nbsp;Clear All</>}
                cancelText='Cancel'
                title='Are you sure you want to start a new analysis?'
                description='This will clear all your current data and start a new analysis.'
                placement='bottomRight'
                okButtonProps={{danger:true}}
                cancelButtonProps={{type:'primary'}}
                onConfirm={() => { setVins([]); setFileList([]); setVinProfiles({}); setProcessedVinsCount(0); setProgress(0); setLoaded(false);}}
              >
                <Button type='primary' style={{position:'fixed', left:'8rem'}}>New Analysis</Button>
              </Popconfirm>
              <Button onClick={exportVinProfilesAsJSON} style={{position:'fixed', left:'8rem',marginTop:'3rem'}}><UploadOutlined />Export to JSON</Button>
              <Button onClick={() => setExpandedVins(Object.keys(vinProfiles))} style={{position:'fixed', left:'8rem',marginTop:'6rem'}}><PlusOutlined />Expand All</Button>
              <Button onClick={() => setExpandedVins([])} style={{position:'fixed', left:'8rem',marginTop:'9rem'}}><MinusOutlined />Collapse All</Button>
              <Collapse
                collapsible='header'
                activeKey={expandedVins}
                onChange={setExpandedVins}
                items={Object.keys(vinProfiles).map((vin) => ({
                  key: vin,
                  label: (
                    <div>
                      {vin}
                      <span className='ada-link' style={{float:'right', fontSize:'smaller'}} onClick={() => {window.open(new URL(`${adaWebEndpoints.showVinEndpoint}/${vin}`, adaWebURL),'_blank');}}>
                        <ExportOutlined style={{marginRight:'0.5em',fontSize:'0.8em'}} /> ADA Web
                      </span>
                    </div>
                  ),
                  children: (
                    <div>
                      <Tabs
                        items={getInfoTabItems(vin)}
                      />
                    </div>
                  )               
                }))}
              />
              <div style={{position:'fixed', right:'1rem', top:'6.5rem', width:'16rem'}}>
                <Collapse
                  accordion
                  size='small'
                  collapsible='header'
                  defaultActiveKey={['options']}
                  items={[
                    {
                      key: 'options', 
                      label: 'Options', 
                      children: (
                        <div style={{padding:'1em 0'}}>
                          <div><Switch onChange={(check, e) => {setSyncTabs(check); if (check) setCurrentTab('info');}} checked={syncTabs} />&emsp;Sync Tabs</div>
                        </div>
                      )
                    },
                    {
                      key: 'export',
                      label: 'Export to Excel',
                      children: (
                        <div style={{padding:'1em 0'}}>
                          <Checkbox.Group options={VINProfileFieldsCheckboxOptions} value={exportExcelFields} onChange={setExportExcelFields} />
                          <Checkbox 
                            indeterminate={exportExcelFields.length > 0 && exportExcelFields.length < VINProfileFieldsCheckboxOptions.length}
                            checked={exportExcelFields.length === VINProfileFieldsCheckboxOptions.length}
                            onChange={(e) => {setExportExcelFields(e.target.checked ? VINProfileFieldsCheckboxOptions.map(o => o.value) : ['vin','device']);}} 
                          >
                            Toggle All
                          </Checkbox>
                          <Divider style={{margin: '1em 0'}} />
                          <Button type='primary' onClick={exportDataToExcel} disabled={exportExcelFields.length === 0}>Export</Button>
                        </div>
                      )
                    }
                  ]}
                />
              </div>
            </>
          }
        </>
      }
    </div>
  )
}

const DividerCSS = { margin: '18px 0 12px' };
